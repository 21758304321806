import { render, staticRenderFns } from "./EssayShow.vue?vue&type=template&id=20035740&"
import script from "./EssayShow.vue?vue&type=script&lang=js&"
export * from "./EssayShow.vue?vue&type=script&lang=js&"
import style0 from "./EssayShow.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/danyelsanches/Documents/projetos/educatena/educatena-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20035740')) {
      api.createRecord('20035740', component.options)
    } else {
      api.reload('20035740', component.options)
    }
    module.hot.accept("./EssayShow.vue?vue&type=template&id=20035740&", function () {
      api.rerender('20035740', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/questionnaires/answers/questions/show/EssayShow.vue"
export default component.exports